import { Location } from "@angular/common";
import { invalid } from "moment";
import { environment } from "../../environments/environment";

export function parseXmlToJson(xml) {
  const json = {};
  for (const res of xml.matchAll(
    /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
  )) {
    const key = res[1] || res[3];
    const value = res[2] && parseXmlToJson(res[2]);
    json[key] = (value && Object.keys(value).length ? value : res[2]) || null;
  }
  return json;
}

export function rutaActual(path) {
  const titlee = path;
  var splitted = titlee.split("/", 3);
  var ruta;
  const encabezado = splitted[splitted.length - 1];
  if (encabezado == "dashboard") {
    ruta = "Dashboard";
  } else if (encabezado == "combustible") {
    ruta = "Combustible por Unidad y Locación";
  } else if (encabezado == "rendimientocomparativo") {
    ruta = "Rendimiento Comparativo";
  } else if (encabezado == "kilometrajerecorrido") {
    ruta = "Kilometraje Recorrido";
  } else if (encabezado == "costosporunidad") {
    ruta = "Costo por Unidad";
  } else if (encabezado == "litrosconsumo") {
    ruta = "Litros Consumidos por Unidad";
  } else if (encabezado == "volcargavehiculo") {
    ruta = "Volumen de Carga por Vehículo";
  } else if (encabezado == "kilometrajerecorrido") {
    ruta = "Kilometraje Recorrido";
  } else if (encabezado == "niveleconconduccion") {
    ruta = "Nivel de Economía en Conducción";
  } else if (encabezado == "preciocomb") {
    ruta = "Precio de Combustible";
  } else if (encabezado == "usocomb") {
    ruta = "Uso de Combustible";
  } else if (encabezado == "redimientodet") {
    ruta = "Rendimiento Detallado";
  } else if (encabezado == "datosconsol") {
    ruta = "Datos Consolidados";
  } else if (encabezado == "ltsvskm") {
    ruta = "Litros vs Kilómetros";
  } else if (encabezado == "llenado") {
    ruta = "Llenado";
  } else {
    ruta = "principal";
  }

  return ruta;
}

export function generarLetra() {
  var letras = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  var numero = (Math.random() * 15).toFixed(0);
  return letras[numero];
}

export function getDateString(date) {
  let final: any;
  let m: string;
  let d: string;

  let day = date.getDate();
  if (day < 10) d = `0${day}`;
  else d = `${day}`;

  let month = date.getMonth() + 1;
  if (month < 10) m = `0${month}`;
  else m = `${month}`;

  let year = date.getFullYear();

  final = `${year}-${m}-${d}`;

  return final;
}

export function getToday() {
  let today: any;
  let date = new Date();

  today = getDateString(date);
  return today;
}

export function getWeekDifference(pivotDate, timeJump) {
  let finalDate = new Date(pivotDate);
  let result: any;

  if (timeJump == "after") finalDate.setDate(finalDate.getDate() + 30);
  else if (timeJump == "before") finalDate.setDate(finalDate.getDate() - 29);

  result = getDateString(finalDate);
  return result;
}

export function getMonthDifferenceBackward(startDate, endDate) {
  const diff = 30;

  startDate = new Date(startDate);
  endDate = new Date(endDate);

  if (startDate == invalid) return 0;
  if (endDate == invalid) return 0;

  if (endDate.getFullYear() < environment.startYear) return 0;

  let realDiff = calculateDiff(startDate, endDate);
  let finalDate = startDate;
  let result: any;

  //**********************************************************************
  // control seleccionado: Fecha Fin
  // control a modificar:  Fecha Inicio
  //**********************************************************************
  if (realDiff > diff) {
    finalDate.setDate(finalDate.getDate() - diff);
    result = getDateString(finalDate);
  } else if (realDiff < 0) {
    finalDate.setDate(finalDate.getDate());
    result = getDateString(finalDate);
  } else {
    result = getDateString(finalDate);
  }

  return result;
  //**********************************************************************
}

export function getMonthDifferenceForward(startDate, endDate) {
  const diff = 30;

  startDate = new Date(startDate);
  endDate = new Date(endDate);

  if (startDate == invalid) return 0;
  if (endDate == invalid) return 0;

  if (startDate.getFullYear() < environment.startYear) return 0;

  let realDiff = calculateDiff(startDate, endDate);
  let finalDate = endDate;
  let result: any;

  //**********************************************************************
  // control seleccionado: Fecha Inicio
  // control a modificar:  Fecha Fin
  //**********************************************************************
  if (realDiff > diff) {
    finalDate.setDate(finalDate.getDate() + diff);
    result = getDateString(finalDate);
  } else if (realDiff < 0) {
    finalDate.setDate(finalDate.getDate() + 2);
    result = getDateString(finalDate);
  } else {
    result = getDateString(finalDate);
  }

  return result;
  //**********************************************************************
}

export function calculateDiff(startDate, endDate) {
  //   let endDate = new Date();
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  let startUTC = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  let endUTC = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  let retorno = Math.floor(endUTC - startUTC);
  retorno = retorno / (1000 * 60 * 60 * 24);

  return retorno;
}

export function obtenerColor(theme) {
  var colores = [];

  if (theme == "light") {
    colores = [
      "BFC8D7",
      "E2D2D2",
      "E3E2B4",
      "A2B59F",
      "E8E7D2",
      "D2D5D8",
      "BDC2BB",
      "C9BA9B",
      "F0E4D4",
      "F9D9CA",
      "D18063",
      "917B56",
      "EDEEF0",
      "EDE1E3",
      "D1DFE8",
      "909FA6",
      "FADCDA",
      "EEB8B8",
      "C5DAD1",
      "D5CB8E",
      "EFEFF1",
      "ECD4D4",
      "CCDBE2",
      "C9CBE0",
      "FDF2F0",
      "F8DAE2",
      "DEB3CF",
      "B57FB3",
      "F7EAE2",
      "EADB80",
      "AEDDEF",
      "E1B4D3",
      "FAF0E4",
      "EECFBB",
      "F6B99D",
      "CB8A90",
      "D5E1DF",
      "EACACB",
      "E2B3A3",
      "A3B6C5",
      "E1F1E7",
      "B1D3C5",
      "CFDD8E",
      "E4BEB3",
      "FCE9DA",
      "FFCEC7",
      "FFD0A6",
      "E098AE",
    ];
  } else {
    colores = [
      "214559",
      "262b2f",
      "00626f",
      "0f3b57",
      "00022e",
      "112222",
      "2a293e",
      "2b6867",
      "29304e",
      "020035",
      "34414e",
      "040348",
      "391285",
      "203e4a",
      "184343",
      "0000aa",
      "000133",
      "033500",
      "373e02",
      "6f7755",
      "547053",
      "11574a",
      "80884e",
      "495e35",
      "696006",
      "11887b",
      "044a05",
      "004953",
      "35654d",
      "014600",
      "3e6257",
      "3d6c54",
      "062e03",
      "004400",
      "b4262a",
      "d1001c",
      "c14a09",
      "7f4330",
      "c65102",
      "ba160c",
      "ca6636",
      "e6ba45",
      "d5b60a",
      "900020",
      "420303",
      "840000",
      "820000",
      "9e1212",
      "800000",
      "4a0100",
      "980036",
      "3d0c02",
      "220a0a",
      "3a181a",
      "af2f0d",
      "9c004a",
      "d90166",
      "cb416b",
      "b0306a",
      "490648",
      "76424e",
      "35063e",
      "80444c",
      "36013f",
      "674c47",
      "280137",
      "4d233d",
      "5c4450",
      "673a3f",
      "605467",
      "553f2d",
      "442200",
      "593c39",
      "742802",
      "341c02",
      "490206",
      "410200",
      "4f1507",
      "754600",
      "7f7053",
      "937a62",
      "5c5337",
      "985538",
      "755139",
      "3b2820",
      "573b2a",
      "415764",
      "48412b",
      "4e5552",
      "333333",
      "363737",
      "646356",
      "716e61",
      "565350",
      "25342b",
      "4e5541",
      "2a2a35",
      "6f828a",
      "404854",
      "4d4b3a",
      "110022",
      "2a2b2d",
      "5a5348",
      "50574c",
      "1d0200",
      "5d5242",
      "3b3c36",
      "1e272c",
      "362d26",
      "171717",
      "23191e",
      "0a0502",
      "161616",
      "080808",
      "2b0202",
      "050d25",
      "302621",
      "1b1811",
      "3b302f",
    ];
  }

  var numero = (Math.random() * (colores.length - 1)).toFixed(0);
  var color = "#" + colores[numero];
  //   console.log(color);
  return color;
}

export function colorHEX() {
  var coolor = "";
  for (var i = 0; i < 6; i++) {
    coolor = coolor + this.generarLetra();
  }
  return "#" + coolor;
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function alert(text): void {
  this.alertsDismiss.push({
    type: "info",
    msg: `${text}`,
    timeout: 5000,
  });
}

export function numeroComa(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
