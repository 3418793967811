import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ServicesService } from "../../services/api.service";
// import { strings } from "@material/snackbar";
import { parseXmlToJson } from "../../shared/functions";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertConfig } from "ngx-bootstrap/alert";

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: "success" });
}

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .alert-md-local {
        background-color: #009688;
        border-color: #00695c;
        color: #fff;
      }
    `,
  ],
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }],
})
export class LoginComponent implements OnInit {
  usuario: string = localStorage.getItem("infoUser");
  clave: string = "";
  autenticado: Boolean = false;

  constructor(
    sanitizer: DomSanitizer,
    private router: Router,
    private services: ServicesService
  ) {}

  alert: any = {};

  ngOnInit(): void {
    //  if (this.usuario && this.usuario != "")
    //    this.router.navigateByUrl("inicio");

    this.ObtenerToken();
  }

  doAlert(_message, _type): void {
    this.alert = {
      type: "info",
      msg: _message,
      timeout: 5000,
    };
  }

  login_click() {
    if (this.validarUsuario()) {
      this.services.login(this.usuario, this.clave).subscribe(
        (res) => {
          if (localStorage.getItem("tokenIndividual") != "") {
            const jsonStr = parseXmlToJson(res);

            const dataDashboard = JSON.parse(jsonStr["string"]);
            // console.log(dataDashboard);

            if (dataDashboard.status != "0") {
              this.doAlert("Acceso Correcto", "success");
              localStorage.setItem("infoUser", this.usuario);
              localStorage.setItem(
                "loginDashboard",
                JSON.stringify(dataDashboard)
              );
              this.router.navigateByUrl("inicio");
            } else {
              this.doAlert(dataDashboard.statusMsg, "error");
            }
          } else {
            this.ObtenerToken();
            if (localStorage.getItem("tokenIndividual") != "") {
              this.login_click();
            } else {
              this.doAlert("Token Individual no generado", "error");
            }
          }
        },
        (error) => {
          this.doAlert("Por favor, intenta ingresar nuevamente.", "error");
        }
      );
      //
    }
  }

  ObtenerToken() {
    this.services.getToken().subscribe(
      (res) => {
        const jsonStr = parseXmlToJson(res);
        let tokenIndividual = JSON.parse(jsonStr["string"]).tokenIndividual;
        localStorage.setItem("tokenIndividual", tokenIndividual);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  validarUsuario(): boolean {
    if (this.usuario == null || this.usuario == "") {
      this.doAlert("Ingrese el nombre de usuario", "error");
      return false;
    }
    if (this.clave == null || this.clave == "") {
      this.doAlert("Ingrese la clave de acceso", "error");
      return false;
    }
    return true;
  }
}
