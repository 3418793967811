import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { InicioComponent } from "./inicio/inicio.component";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { LogoutComponent } from "./views/logout/logout.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "inicio",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "inicio",
    component: InicioComponent,
    data: {
      title: "Página de Inicio",
    },
  },
  {
    path: "logout",
    component: LogoutComponent,
    data: {
      title: "Logout Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Tracklink | Gráficos",
    },
    children: [
      {
        path: "graphic",
        loadChildren: () =>
          import("./views/graphic/graphic.module").then((m) => m.GraphicModule),
      },
      {
        path: "fuel",
        loadChildren: () =>
          import("./views/fuel/fuel.module").then((m) => m.FuelModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
