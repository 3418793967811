import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  url = environment.apiURL + "/WSNiDashboard/WSNiDashboard.asmx"; //'WSNiDashboard'
  tokenGeneral = "9f57-F0d1-98dc-4B34";
  userdev = "NIDashboard_user";

  constructor(private http: HttpClient) {}

  getToken(): Observable<any> {
    let urlget =
      this.url +
      "/getToken?tokenGeneral=" +
      this.tokenGeneral +
      "&userID=" +
      this.userdev;

    return this.http.get(urlget, { responseType: "text" });
  }

  login(usuario: string, clave: string): Observable<any> {
    let tokenIndividual = localStorage.getItem("tokenIndividual");
    const url =
      this.url +
      `/loginDashboard?tokenGeneral=` +
      this.tokenGeneral +
      `&tokenIndividual=` +
      tokenIndividual +
      `&userID=` +
      this.userdev +
      `&userIDCustomer=` +
      usuario +
      `&password=` +
      clave;

    return this.http.get(url, { responseType: "text" });
  }

  consultarReporte(data: any): Observable<any> {
    let usuario = localStorage.getItem("infoUser");
    let tokenIndividual = localStorage.getItem("tokenIndividual");

    const url =
      this.url +
      `/reportDashboard?tokenGeneral=` +
      this.tokenGeneral +
      `&tokenIndividual=` +
      tokenIndividual +
      `&userID=` +
      this.userdev +
      `&userIDCustomer=` +
      usuario +
      `&alias_=` +
      data.vehiculo +
      `&alert=` +
      data.alerta +
      `&startDate=` +
      data.fechadesde +
      `&endDate=` +
      data.fechahasta;

    return this.http.get(url, { responseType: "text" });
  }

  consultarVehiculo(data: any): Observable<any> {
    let usuario = localStorage.getItem("infoUser");
    let tokenIndividual = localStorage.getItem("tokenIndividual");

    const url =
      this.url +
      `/reportVehicle?tokenGeneral=` +
      this.tokenGeneral +
      `&tokenIndividual=` +
      tokenIndividual +
      `&userID=` +
      this.userdev +
      `&userIDCustomer=` +
      usuario +
      `&alias_=` +
      data.vehiculo +
      `&startDate=` +
      data.fechadesde +
      `&endDate=` +
      data.fechahasta;

    return this.http.get(url, { responseType: "text" });
  }

  consultarVehiculoDiario(data: any): Observable<any> {
    let usuario = localStorage.getItem("infoUser");
    let tokenIndividual = localStorage.getItem("tokenIndividual");

    const url =
      this.url +
      `/reportVehicleDay?tokenGeneral=` +
      this.tokenGeneral +
      `&tokenIndividual=` +
      tokenIndividual +
      `&userID=` +
      this.userdev +
      `&userIDCustomer=` +
      usuario +
      `&alias_=` +
      data.vehiculo +
      `&startDate=` +
      data.fechadesde +
      `&endDate=` +
      data.fechahasta;

    return this.http.get(url, { responseType: "text" });
  }

  consultarReporteCombustible(data: any): Observable<any> {
    let usuario = localStorage.getItem("infoUser");
    let tokenIndividual = localStorage.getItem("tokenIndividual");

    const url =
      this.url +
      `/reportDashboardFuel?tokenGeneral=` +
      this.tokenGeneral +
      `&tokenIndividual=` +
      tokenIndividual +
      `&userID=` +
      this.userdev +
      `&userIDCustomer=` +
      usuario +
      `&alias_=` +
      data.vehiculo +
      `&startDate=` +
      data.fechadesde +
      `&endDate=` +
      data.fechahasta;

    return this.http.get(url, { responseType: "text" });
  }
}
