import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.scss"],
})
export class InicioComponent implements OnInit {
  Usuario: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.Usuario = localStorage.getItem("infoUser");

    if (this.Usuario && this.Usuario != "") {
      //   loading.dismiss();
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  ionViewDidEnter() {}
}
